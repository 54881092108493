import React, {Fragment, FunctionComponent, useEffect, useMemo, useState} from 'react';
import YouTube, {YouTubeEvent} from "react-youtube";
import {useSearchParams} from "react-router-dom";

type Log = { id: number, value: string, date: Date }

const ON_PLAYER_READY_EVENT: string = 'Ready to start'

export const useDocumentVisible = (documentElement = document) => {
    const [documentVisible, setDocumentVisible] = useState(
        documentElement.visibilityState,
    );

    useEffect(() => {
        const handleVisibilityChange = () => setDocumentVisible(documentElement.visibilityState)

        documentElement.addEventListener(
            "visibilitychange",
            handleVisibilityChange,
        );

        return () =>
            documentElement.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
    }, [documentElement]);

    return documentVisible === "visible";
};

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const apiRecordEvent = async (eventType: number, videoId: string | null, userId: string | null, event: YouTubeEvent) => {
    return await fetch("https://gpa-tracker-api.sysei.mx/v1/youtube-events/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "event_type": eventType,
            "video_id": videoId,
            "user_id": userId,
            "playback_quality": event.target.getPlaybackQuality(),
            "playback_rate": event.target.getPlaybackRate(),
            "error_code": null
        })
    })
}

const apiRecordTimeWatched = async (videoId: string | null, userId: string | null, hours: number, minutes: number, seconds: number, milliseconds: number) => {
    return await fetch("https://gpa-tracker-api.sysei.mx/v1/youtube-time-watched/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "video_id": videoId,
            "user_id": userId,
            "hours": hours,
            "minutes": minutes,
            "seconds": seconds,
            "milliseconds": milliseconds,
        })
    })
}


const VideoTrackingPlayer: FunctionComponent<{
    debugMode?: boolean,
}> = ({debugMode}) => {
    const [searchParams] = useSearchParams();

    const [isReady, setIsReady] = useState(false);

    const [time, setTime] = useState(0)
    const [isRunning, setIsRunning] = useState(false)
    const [logs, setLogs] = useState<Log[]>([])

    // const [documentVisible, setDocumentVisible] = useState(
    //     document.visibilityState,
    // );

    const {hours, minutes, seconds, milliseconds} = useMemo(() => ({
        hours: Math.floor(time / 360000),
        minutes: Math.floor((time % 360000) / 6000),
        seconds: Math.floor((time % 6000) / 100),
        milliseconds: time % 100,
    }), [time])

    // useEffect(() => {
    //     const unloadCallback = (event: any) => {
    //         event.preventDefault()
    //         event.returnValue = ""
    //         return ""
    //     };
    //
    //     window.addEventListener("beforeunload", unloadCallback)
    //     return () => window.removeEventListener("beforeunload", unloadCallback)
    // }, [])

    const videoId = useMemo(() => searchParams.get("id"), [searchParams])
    const userId = useMemo(() => searchParams.get("user-id"), [searchParams])

    useEffect(() => {
        const handleVisibilityChange = () => setLogs(prevState => ([
            ...prevState,
            {id: prevState.length + 1, value: `Tab visibility: ${document.visibilityState}`, date: new Date()}
        ]))//setDocumentVisible(document.visibilityState)

        document.addEventListener(
            "visibilitychange",
            handleVisibilityChange,
        );

        return () =>
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
    }, []);

    useEffect(() => {
        let intervalId: NodeJS.Timer | undefined
        if (isRunning && videoId && userId) {
            intervalId = setInterval(async () => {
                setTime(time + 1)
                await apiRecordTimeWatched(videoId, userId, hours, minutes, seconds, milliseconds)
            }, 8)
        }
        return () => clearInterval(intervalId)
    }, [hours, isRunning, milliseconds, minutes, seconds, time, userId, videoId]);

    if (videoId === null) return <>Loading videoId...</>

    const onReadyCallback = async (event: YouTubeEvent) => {
        if (!isReady) {
            try {
                await apiRecordEvent(-2, videoId, userId, event)

                setLogs((prevState) => {
                    if (!prevState.find(log => log.value === ON_PLAYER_READY_EVENT)) {
                        return [...prevState, {
                            id: prevState.length + 1,
                            value: ON_PLAYER_READY_EVENT,
                            date: new Date()
                        }]
                    }
                    return prevState
                })
                setIsReady(true)
            } catch (e) {
                console.error(e)
                setIsReady(false)
            }
        }
    }

    return (
        <>
            <div className="video-container">
                <YouTube
                    videoId={videoId}
                    opts={{
                        height: '100%',
                        width: '100%',
                        playerVars: {
                            autoplay: 0,
                        },
                    }}
                    onReady={onReadyCallback}
                    onPlay={async (event) => {
                        await apiRecordEvent(1, videoId, userId, event)

                        setLogs(prevState => (
                            [...prevState, {
                                id: prevState.length + 1,
                                value: "Play",
                                date: new Date()
                            }]
                        ))
                        setIsRunning(true)
                    }}
                    onEnd={async (event) => {
                        await apiRecordEvent(0, videoId, userId, event)

                        setLogs(prevState => (
                            [...prevState, {id: prevState.length + 1, value: "Completed", date: new Date()}]
                        ))
                        setIsRunning(false)
                    }}
                    onPause={async (event) => {
                        await apiRecordEvent(2, videoId, userId, event)

                        setLogs(prevState => (
                            [...prevState, {
                                id: prevState.length + 1,
                                value: "Pause",
                                date: new Date()
                            }]
                        ))
                        setIsRunning(false)
                    }}
                    onStateChange={(e) => {
                        console.log("onStateChange", e.data)
                    }}
                    onPlaybackRateChange={(e) => {
                        console.log("onPlaybackRateChange", e.data)
                    }}
                    onPlaybackQualityChange={(e) => console.log("onPlaybackQualityChange", e.data)}
                    onError={(e) => {
                        console.log("onError", e.data)
                    }}
                />
            </div>
            {debugMode && (
                <div className="absolute top-4 right-4 w-1/4">
                    <div className="border rounded-md bg-white opacity-55">
                        <div className="flex items-center justify-center space-x-2 py-2">
                            <p>Video timer: </p>
                            <p className="stopwatch-time">
                                {hours}:{minutes.toString().padStart(2, "0")}:
                                {seconds.toString().padStart(2, "0")}:
                                {milliseconds.toString().padStart(2, "0")}
                            </p>
                        </div>
                        {/*<div className="stopwatch-buttons">*/}
                        {/*    <button className="stopwatch-button" onClick={startAndStop}>*/}
                        {/*        {isRunning ? "Stop" : "Start"}*/}
                        {/*    </button>*/}
                        {/*    <button className="stopwatch-button" onClick={reset}>*/}
                        {/*        Reset*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <ul className="pt-4 divide-y">
                            {logs.map((log) => (
                                <li key={`log-${log.id}`} className="relative flex gap-x-4">
                                    <div className={'absolute left-0 top-0 flex w-6'}>
                                        <div className="w-px bg-gray-200"/>
                                    </div>
                                    <div
                                        className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                        <div
                                            className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>
                                    </div>
                                    <p className="flex-auto py-0.5 text-xs text-left leading-5 text-gray-500">
                                        {log.value}
                                    </p>
                                    <time dateTime={log.date.toLocaleDateString()}
                                          className="flex-none py-0.5 text-xs leading-5 text-gray-500 pr-4">
                                        {log.date.toString()}
                                    </time>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    )
}


function App() {
    // const [page, setPage] = useState<MoodlePage | null>(null)
    // const [open, setOpen] = useState(false)

    // const {data, isError, isLoading} = useQuery(['mod_page_get_pages_by_courses', 2], async () => {
    //     try {
    //         const res = await fetch(
    //             `http://moodle.localhost/webservice/rest/server.php?wstoken=${WS_TOKEN}&wsfunction=mod_page_get_pages_by_courses&moodlewsrestformat=json&courseids[0]=2`
    //         )
    //         return await res.json()
    //     } catch (e) {
    //         console.error(e)
    //     }
    // })
    //
    // if (isError) return <>Error...</>
    // if (isLoading) return <>Loading...</>

    // const markResourceAsCompleted = async (cmid: MoodlePage["coursemodule"]) => {
    //     try {
    //         const res = await fetch(`http://moodle.localhost/webservice/rest/server.php?wstoken=${WS_TOKEN}&wsfunction=core_completion_update_activity_completion_status_manually&moodlewsrestformat=json&cmid=${cmid}&completed=1`)
    //         if (res.ok) {
    //             // alert(`Resource ${cmid} marked as completed!`)
    //         } else {
    //             console.log(await res.json())
    //         }
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }

    // const pages: MoodlePage[] = data?.pages ?? []

    // const pages: MoodlePage[] = [
    //     {content: '', id: 1, name: '', timemodified: 12345678, coursemodule: 12345678, visible: true, course: 1}
    // ]

    return (
        <>
            <VideoTrackingPlayer debugMode={true}/>
            {/*<Transition.Root show={open} as={Fragment}>*/}
            {/*    <Dialog as="div" className="relative z-10" onClose={setOpen}>*/}
            {/*        <div className="fixed inset-0"/>*/}
            {/*        <div className="fixed inset-0 overflow-hidden">*/}
            {/*            <div className="absolute inset-0 overflow-hidden">*/}
            {/*                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">*/}
            {/*                    <Transition.Child*/}
            {/*                        as={Fragment}*/}
            {/*                        enter="transform transition ease-in-out duration-500 sm:duration-700"*/}
            {/*                        enterFrom="translate-x-full"*/}
            {/*                        enterTo="translate-x-0"*/}
            {/*                        leave="transform transition ease-in-out duration-500 sm:duration-700"*/}
            {/*                        leaveFrom="translate-x-0"*/}
            {/*                        leaveTo="translate-x-full"*/}
            {/*                    >*/}
            {/*                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">*/}
            {/*                            <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">*/}
            {/*                                <div className="px-4 sm:px-6">*/}
            {/*                                    <div className="flex items-start justify-between">*/}
            {/*                                        <Dialog.Title*/}
            {/*                                            className="text-base font-semibold leading-6 text-gray-900">*/}
            {/*                                            Logs*/}
            {/*                                        </Dialog.Title>*/}
            {/*                                        <div className="ml-3 flex h-7 items-center">*/}
            {/*                                            <button*/}
            {/*                                                type="button"*/}
            {/*                                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
            {/*                                                onClick={() => setOpen(false)}*/}
            {/*                                            >*/}
            {/*                                                <span className="absolute -inset-2.5"/>*/}
            {/*                                                <span className="sr-only">Close panel</span>*/}
            {/*                                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>*/}
            {/*                                            </button>*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                                <div*/}
            {/*                                    className="relative mt-6 flex-1 px-4 sm:px-6">/!* Your content *!/</div>*/}
            {/*                            </div>*/}
            {/*                        </Dialog.Panel>*/}
            {/*                    </Transition.Child>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Dialog>*/}
            {/*</Transition.Root>*/}
            {/*<div className="bg-white py-24 sm:py-32">*/}
            {/*    <div className="mx-auto max-w-7xl px-6 lg:px-8">*/}
            {/*<div className="mx-auto max-w-2xl text-center">*/}
            {/*    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Course 001</h2>*/}
            {/*    <p className="mt-2 text-lg leading-8 text-gray-600">*/}
            {/*        Learn how to grow your business with our expert advice.*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*{page === null && (*/}
            {/*    <>*/}
            {/*        <div*/}
            {/*            className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">*/}
            {/*            {pages.map((page, pageIdx) => {*/}
            {/*                const dateTime = new Date(page.timemodified * 1000)*/}
            {/*                return (*/}
            {/*                    <article*/}
            {/*                        key={page.id}*/}
            {/*                        className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"*/}
            {/*                    >*/}
            {/*                        <img src={posts[pageIdx].imageUrl} alt=""*/}
            {/*                             className="absolute inset-0 -z-10 h-full w-full object-cover"/>*/}
            {/*                        <div*/}
            {/*                            className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40"/>*/}
            {/*                        <div*/}
            {/*                            className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>*/}
            {/*                        <div*/}
            {/*                            className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">*/}
            {/*                            <time dateTime={dateTime.toISOString()} className="mr-8">*/}
            {/*                                {dateTime.toLocaleDateString("es-MX")}*/}
            {/*                            </time>*/}
            {/*                            <div className="-ml-4 flex items-center gap-x-4">*/}
            {/*                                <svg viewBox="0 0 2 2"*/}
            {/*                                     className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">*/}
            {/*                                    <circle cx={1} cy={1} r={1}/>*/}
            {/*                                </svg>*/}
            {/*                                <div className="flex gap-x-2.5">*/}
            {/*                                    <img src={posts[pageIdx].author.imageUrl} alt=""*/}
            {/*                                         className="h-6 w-6 flex-none rounded-full bg-white/10"/>*/}
            {/*                                    {posts[pageIdx].author.name}*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <h3 className="mt-3 text-lg font-semibold leading-6 text-white">*/}
            {/*                            <button type={"button"} onClick={() => {*/}
            {/*                                setPage(page)*/}
            {/*                                // setOpen(true)*/}
            {/*                            }}>*/}
            {/*                                <span className="absolute inset-0"/>*/}
            {/*                                {page.name}*/}
            {/*                            </button>*/}
            {/*                        </h3>*/}
            {/*                    </article>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*)}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
}

export default App;
